<template>
    <LayoutLogin v-bind="{loading, error, restartLogin}">
        <div v-if="initialised && !isLoggedIn">
            <div v-if="!email">
                <FormLoginEmailChange :initEmail="email" :initRemember="remember" :changeEmail="changeEmail" />
            </div>
            <div v-else>
                <FormLoginEmailSelected :initEmail="email" :clearEmail="clearEmail" />
            </div>
            <div v-if="email && emailed && !error">
                <div class="px-1 text-base">
                    <p class="mb-6">
                        <strong>We have sent you an email with a link.</strong>
                        The email might take a little time to arrive, or land in your spam.
                    </p>
                    <div class="mb-6">
                        The link will sign you in to the website, but if you prefer
                        - you can enter the login code manually.
                    </div>
                </div>
                <div class="flex flex-row">
                    <ButtonAction
                        class="-ml-2"
                        buttonPadding="px-2 py-2"
                        buttonColor="bg-transparent hover:bg-zinc-100"
                        buttonText="Enter Code Manually"
                        buttonRoute="/login-code"
                    ></ButtonAction>
                    <div class="flex grow"></div>
                    <ButtonAction
                        class="-mr-2"
                        buttonPadding="px-2 py-2"
                        buttonColor="bg-transparent hover:bg-zinc-100"
                        buttonText="Resend Email"
                        :buttonClick="emailPasscode"
                    ></ButtonAction>
                </div>
            </div>
            <div v-else-if="email && !error">
                <div class="flex flex-row">
                    <ButtonAction
                        class="-ml-1"
                        buttonPadding="px-2 py-2"
                        buttonColor="bg-transparent hover:bg-zinc-100"
                        buttonText="Create account"
                        buttonRoute="/signup"
                    ></ButtonAction>
                    <div class="flex grow"></div>
                    <ButtonAction
                        buttonText="Login"
                        :buttonClick="emailPasscode"
                    ></ButtonAction>
                </div>
            </div>
        </div>
        <LoadingWidget v-else></LoadingWidget>
    </LayoutLogin>
</template>

<script setup>
const {isLoggedIn} = storeToRefs(useStoreAuth());

const {initialised, email, remember, emailed, loading, error} = storeToRefs(useStoreLogin());
const {initLogin, changeEmail, clearEmail, emailPasscode, restartLogin} = useStoreLogin();

onMounted(() => {
    if (!initialised.value) {
        const code = useRoute().params.passcode !== 'code' ? useRoute().params.passcode : undefined;
        initLogin(code);
    }
});

const $config = useRuntimeConfig();
useSeo().buildHead({
    title: 'Login | ' + $config.public.siteName,
    description: 'Sign in to ' + $config.public.siteName + '.',
    index: true,
});
</script>
