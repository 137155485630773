<template>
    <div class="mb-6 flex flex-row border-y border-zinc-200 bg-zinc-50 p-4">
        <div class="grow truncate">
            <div class="mb-1 text-lg font-medium">
                Email
            </div>
            <div>
                {{ email }}
            </div>
        </div>
        <div class="my-auto">
            <ButtonAction
                buttonColor="bg-transparent text-zinc-400 hover:text-zinc-700"
                buttonPadding="px-2 py-2"
                :buttonClick="clearEmail"
            >
                <template v-slot:left>
                    <IconClose></IconClose>
                </template>
            </ButtonAction>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormLoginEmailSelected',
    props: {
        initEmail: {
            type: String,
        },
        clearEmail: {
            type: Function,
        },
    },
    data () {
        return {
            email: null,
        };
    },
    mounted: function () {
        this.email = this.initEmail;
    },
};
</script>
